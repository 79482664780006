<template>
  <div class="app-container">
    <div class="filter-container">
      <el-input v-model="listQuery.keyword" placeholder="请输入标签/设备名称" style="width: 220px;" class="filter-item" clearable/>
      <el-select class="filter-item" v-model="listQuery.status" placeholder="请选择状态" style="width: 140px" clearable>
        <el-option label="正常" :value="1"></el-option>
        <el-option label="禁用" :value="2"></el-option>
      </el-select>
      <el-button class="filter-item" type="primary" icon="el-icon-search" @click="handleFilter">搜索</el-button>
      <el-button class="filter-item" type="primary" icon="el-icon-edit" @click="handleAdd">添加</el-button>
    </div>
    <div class="filter-container">
      <el-button-group>
        <el-button type="primary" size="small" @click="handleBatchOption('open', '')" :disabled="ids.length <= 0">一键开箱</el-button>
        <el-button type="success" size="small" @click="handleBatchOption('heat', '1')" :disabled="ids.length <= 0">一键加热</el-button>
        <el-button type="success" size="small" @click="handleBatchOption('heat', '0')" :disabled="ids.length <= 0">一键停止加热</el-button>
        <el-button type="info" size="small" @click="handleBatchOption('lamp', '1')" :disabled="ids.length <= 0">一键消毒</el-button>
        <el-button type="info" size="small" @click="handleBatchOption('lamp', '0')" :disabled="ids.length <= 0">一键停止消毒</el-button>
        <el-button type="warning" size="small" @click="handleBatchOption('light', '1')" :disabled="ids.length <= 0">一键开灯</el-button>
        <el-button type="warning" size="small" @click="handleBatchOption('light', '0')" :disabled="ids.length <= 0">一键关灯</el-button>
      </el-button-group>
    </div>
    <el-table v-loading="listLoading" :data="list" @selection-change="handleSelectionChange" element-loading-text="Loading" fit highlight-current-row>
      <el-table-column type="selection" align="center" width="40"></el-table-column>
      <el-table-column align="center" label="ID" width="60">
        <template slot-scope="scope">
          <!-- {{ scope.$index }} -->
          {{ scope.row.id }}
        </template>
      </el-table-column>
      <el-table-column label="学校" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.school.school_name }}
        </template>
      </el-table-column>
      <el-table-column label="标签" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.tag }}
        </template>
      </el-table-column>
      <el-table-column label="设备名称" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.device_address_and_name }}
        </template>
      </el-table-column>
      <el-table-column label="设备编号" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.device_id }}
        </template>
      </el-table-column>
      <el-table-column label="设备密钥" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.secret_key }}
        </template>
      </el-table-column>
      <el-table-column label="屏幕二维码" min-width="110" align="center">
        <template slot-scope="scope">
          <img :src="scope.row.qr_url" class="user-avatar">
        </template>
      </el-table-column>
      <el-table-column label="权重值" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.weight }}
        </template>
      </el-table-column>
      <el-table-column label="禁用状态" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.status == 1 ? '正常' : '禁用' }}
        </template>
      </el-table-column>
      <el-table-column label="定时消毒" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.clean_time ? `${scope.row.clean_time[0]}-${scope.row.clean_time[1]}` : '' }}
        </template>
      </el-table-column>
      <el-table-column label="投餐员" min-width="110" align="center">
        <template v-if="scope.row.sendman" slot-scope="scope">
          <div>{{scope.row.sendman.name}}</div>
          <div>{{scope.row.sendman.phone}}</div>
        </template>
      </el-table-column>
      <el-table-column label="操作" fixed="right" align="center" width="230" class-name="small-padding fixed-width">
        <template slot-scope="scope">
          <div>
            <el-button size="mini" type="primary" @click="handleEdit(scope.row)">编辑</el-button>
            <el-button size="mini" type="danger" @click="handleDel(scope.row)">删除</el-button>
          </div>
          <!-- <div style="margin-top:5px;">
            <el-button size="mini" type="primary" @click="handleOpen(scope.row)">打开所有箱子</el-button>
          </div> -->
        </template>
      </el-table-column>
    </el-table>

    <div class="pagination-container">
      <el-pagination :current-page="listQuery.page" :page-sizes="[5,10,20,30, 50]" :page-size="listQuery.limit" :total="total" background layout="total, sizes, prev, pager, next, jumper" @size-change="handleSizeChange" @current-change="handleCurrentChange" />
    </div>

    <el-dialog :visible.sync="dialogVisible" :close-on-click-modal="false">
      <el-form ref="form" :model="form" label-position="left" label-width="90px">
        <el-form-item label="标签">
          <el-input v-model="form.tag" placeholder="用户选择设备需通过标签筛选" />
        </el-form-item>
        <el-form-item label="设备名称">
          <el-input v-model="form.device_address_and_name" placeholder="请输入设备名称" />
        </el-form-item>
        <el-form-item label="设备编号">
          <el-input v-model="form.device_id" placeholder="请输入设备编号" />
        </el-form-item>
        <el-form-item label="设备密钥">
          <el-input v-model="form.secret_key" placeholder="请输入设备密钥" />
        </el-form-item>
        <el-form-item label="屏幕二维码">
          <upload-one v-model="form.qr_url"></upload-one>
        </el-form-item>
        <el-form-item label="权重值">
          <el-input v-model="form.weight" placeholder="请输入权重值，值越大越优先" />
        </el-form-item>
        <el-form-item label="禁用状态">
          <el-radio v-model="form.status" :label="1" border size="small">正常</el-radio>
          <el-radio v-model="form.status" :label="2" border size="small">禁用</el-radio>
          </el-select>
        </el-form-item>
        <el-form-item label="定时消毒">
          <el-time-picker
            size="small"
            is-range
            value-format="HH:mm"
            v-model="form.clean_time"
            range-separator="至"
            start-placeholder="开始时间"
            end-placeholder="结束时间"
            placeholder="选择时间范围">
          </el-time-picker>
        </el-form-item>
        <el-form-item label="投餐员">
          <el-select v-model="form.sendman_id" filterable class="filter-item" placeholder="投餐员" style="width: 140px" clearable>
            <el-option v-for="item in sendmans" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取消</el-button>
        <el-button type="primary" @click="saveData" :loading="btnLoading">确定</el-button>
      </div>
    </el-dialog>

  </div>
</template>

<script>
import request from "@/utils/request";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      shops: [],
      categories: [],
      sendmans: [],
      list: null,
      total: null,
      listLoading: true,
      listQuery: {
        page: 1,
        limit: 10,
        keyword: "",
        school_id: "",
        status: "",
      },
      dialogVisible: false,
      btnLoading: false,
      form: {
        id: "",
        tag: "",
        school_id: "",
        device_address_and_name: "",
        device_id: "",
        secret_key: "",
        weight: 0,
        qr_url: "",
        status: 1,
        clean_time: null,
        sendman_id: '',
      },
      ids: [],
    };
  },
  created() {
    this.getList();
    this.getSendmanList();
  },
  computed: {
    ...mapGetters(["schools", "school_id"])
  },
  watch: {
    "listQuery.shop_id": {
      handler(newValue, oldValue) {
        if (newValue === "") {
          this.categories = [];
          this.listQuery.category_id = "";
        } else if (newValue !== oldValue) {
          this.listQuery.category_id = "";
          this.getCategoryList();
        }
      },
      deep: true
    },
    "form.shop_id": {
      handler(newValue, oldValue) {
        if (newValue === "") {
          this.categories = [];
          this.form.category_id = "";
        } else if (newValue !== oldValue) {
          this.form.category_id = "";
          this.getCategoryCopyList();
        }
      },
      deep: true
    },
    school_id(newVal, oldVal) {
      this.listQuery.school_id = newVal;
      this.getList();
      this.getSendmanList();
    },
    dialogVisible(newVal, oldVal) {
      // 编辑框隐藏，马上清除旧数据
      if (newVal === false) {
        this.form = {
          id: "",
          tag: "",
          school_id: "",
          device_address_and_name: "",
          device_id: "",
          secret_key: "",
          weight: 0,
          qr_url: "",
          status: 1,
          clean_time: null,
          sendman_id: '',
        };
      }
    }
  },
  methods: {
    getList() {
      this.listQuery.school_id = this.school_id;
      this.listLoading = true;
      request({
        url: "/api/backend/device/list",
        method: "get",
        params: this.listQuery
      }).then(response => {
        this.list = response.data.data;
        this.total = response.data.total;
        this.listLoading = false;
      });
    },
    getCategoryCopyList() {
      request({
        url: "/api/backend/category/list",
        method: "get",
        params: {
          page: 1,
          limit: 1000000,
          school_id: this.school_id,
          shop_id: this.form.shop_id
        }
      }).then(response => {
        this.categoriesCopy = response.data.data;
      });
    },
    getSendmanList() {
      request({
        url: "/api/backend/sendman/list",
        method: "get",
        params: {
          page: 1,
          limit: 100000,
          school_id: this.school_id,
        }
      }).then(response => {
        this.sendmans = response.data.data;
      });
    },
    handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },
    handleSizeChange(val) {
      this.listQuery.limit = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.listQuery.page = val;
      this.getList();
    },
    handleAdd() {
      this.dialogVisible = true
    },
    handleEdit(item) {
      this.form = {
          id: item.id,
          tag: item.tag,
          school_id: item.school_id,
          device_address_and_name: item.device_address_and_name,
          device_id: item.device_id,
          secret_key: item.secret_key,
          weight: item.weight,
          qr_url: item.qr_url,
          status: item.status,
          clean_time: item.clean_time,
          sendman_id: item.sendman_id,
      };
      this.dialogVisible = true;
    },
    handleOpen(item) {
      this.$confirm("确定要打开该设备所有箱子吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        request({
          url: "/api/backend/device/open",
          method: "get",
          params: {
            id: item.id
          }
        }).then(() => {
          this.getList();
          this.$message({
            type: "success",
            message: "操作成功!"
          });
        });
      });
    },
    handleBatchOption(option, status) {
      if (this.ids.length <= 0) {
        this.$message({
          type: "warning",
          message: "请选择项!"
        });
        return;
      }
      this.$confirm("确定要提交该操作吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        request({
          url: "/api/backend/device/batchOption",
          method: "post",
          data: {
            ids: this.ids,
            option: option,
            status: status,
          }
        }).then(() => {
          this.getList();
          this.$message({
            type: "success",
            message: "操作成功!"
          });
        });
      });
    },
    handleDel(item) {
      this.$confirm("确定要删除该设备吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        request({
          url: "/api/backend/device/del",
          method: "get",
          params: {
            id: item.id
          }
        }).then(() => {
          // 删除最后一条数据时无数据问题
          this.list.length <= 1 ? this.listQuery.page-- : false;
          this.getList();
          this.$message({
            type: "success",
            message: "操作成功!"
          });
        });
      });
    },
    handleSelectionChange(val) {
      let ids = [];
      val.forEach(element => {
        ids.push(element.id);
      });
      this.ids = ids;
    },
    saveData() {
      this.form.school_id = this.school_id;
      if (!this.form.school_id) {
        this.$message({
          type: "warning",
          message: "请先选择学校"
        });
        return;
      }
      if (!this.form.tag) {
        this.$message({
          type: "warning",
          message: "请输入标签"
        });
        return;
      }
      if (!this.form.device_address_and_name) {
        this.$message({
          type: "warning",
          message: "请输入设备名称"
        });
        return;
      }
      if (!this.form.device_id) {
        this.$message({
          type: "warning",
          message: "请输入设备编号"
        });
        return;
      }
      if (!this.form.secret_key) {
        this.$message({
          type: "warning",
          message: "请输入设备密钥"
        });
        return;
      }
      if (!this.form.qr_url) {
        this.$message({
          type: "warning",
          message: "请上传屏幕二维码"
        });
        return;
      }
      if (!this.form.status) {
        this.$message({
          type: "warning",
          message: "请选择状态"
        });
        return;
      }
      this.btnLoading = true;
      request({
        url: "/api/backend/device/save",
        method: "post",
        data: this.form
      })
        .then(() => {
          this.dialogVisible = false;
          this.getList();
          this.$message({
            type: "success",
            message: "操作成功!"
          });
        })
        .finally(() => {
          this.btnLoading = false;
        });
    },
    handleDownload() {}
  }
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.user-avatar {
  width: 40px;
  height: 40px;
  border-radius: 6px;
}
</style>
